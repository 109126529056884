import {
  grid,
  officeBuilding,
  briefCase,
  template,
  contact,
  videoCall,
  report,
  users,
  clock,
  notification,
  plugin,
  help,
  documentText,
  netdoc,
  openNewWindow,
  ai,
  iManageIcon
} from "script/icons";
import { sidebarRoute } from "./sidebarRoute";

function getTranlationPath(name) {
  return `layout.sidebar.nav_items.${name}`;
}

const _nav = [
  {
    id: 1,
    _tag: "CSidebarNavItem",
    name: getTranlationPath("dashboard"),
    to: "/",
    icon: grid,
  },
  {
    id: 2,
    _tag: "CSidebarNavDropdown",
    name: getTranlationPath("firm"),
    route: "/firm",
    icon: officeBuilding,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("profile"),
        to: "/firm/profile",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("settings"),
        to: "/firm/settings",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("naming_policy"),
        to: "/firm/policy",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("plugins"),
        to: "/firm/plugins",
      },
    ],
  }, {
    id: 3,
    _tag: "CSidebarNavDropdown",
    name: getTranlationPath("users"),
    route: "/users",
    icon: users,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("manage_users"),
        to: "/users",
      },
      // {
      //   _tag: "CSidebarNavItem",
      //   name: getTranlationPath("user_activity_report"),
      //   to: "/users/activity",
      // },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("audit_user"),
        to: "/users/audit-user",
      },
    ],
  },
  {
    id: 4,
    _tag: "CSidebarNavDropdown",
    name: getTranlationPath("matters"),
    route: "/matter",
    icon: briefCase,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("manage_matters"),
        to: "/matter",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("customize_deadlines"),
        to: "/matter/custom-deadline",
      },
      // {
      //   _tag: "CSidebarNavItem",
      //   name: getTranlationPath("manage_group"),
      //   to: "/group/manage-group",
      // },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("copy_matter_template"),
        to: "/matter/copy-template",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("create_new_matter"),
        to: "/matter/create-matter",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("matter_tag"),
        to: "/matter/tags",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("task"),
        to: "/matter/task",
      },

    ],
  },
  {
    id: 5,
    _tag: "CSidebarNavDropdown",
    name: getTranlationPath("reports"),
    route: "/report",
    icon: report,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("data_range_report"),
        to: "/report/date-range",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("case_sharing_report"),
        to: "/report/case-sharing",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("schedule_teams_report"),
        to: "/report/report-scheduler",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("download_report"),
        to: "/report/download",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("rule_change_report"),
        to: "/report/rule-change",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("modified_deadline_report"),
        to: "/report/modified-deadline",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("matter_archive_report"),
        to: "/report/archive-report",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("audit_user_calander"),
        to: "/report/audit-user-calander",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("matter_report"),
        to: "/report/matter-report",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("audit_firm_deadlines"),
        to: "/report/audit-firm-deadlines",
      },
    ],
  },
  {
    id: 6,
    _tag: "CSidebarNavDropdown",
    name: getTranlationPath("activity"),
    icon: notification,
    route: "/activity",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("user_activity_report"),
        to: "/users/activity",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("push_notifications"),
        to: "/activity/notifications",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("deadline_audit"),
        to: "/activity/deadline-audit",
      },
    ],
  },
  {
    id: 7,
    _tag: "CSidebarNavDropdown",
    name: getTranlationPath("microsoft_teams"),
    icon: videoCall,
    route: "/teams",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("manage_teams_templates"),
        to: "/teams/templates",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("schedule_teams_report"),
        to: "/teams/report-scheduler",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("config_meetings"),
        to: "/teams/meeting",
      },

    ],
  },
  {
    id: 8,
    _tag: "CSidebarNavDropdown",
    name: getTranlationPath("netdocuments"),
    route: "/netdocuments",
    icon: netdoc,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("customized_link"),
        to: "/netdocuments/webpagelink",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("view_matters"),
        to: "/netdocuments/ndmatters",
      },
    ],
  },
  {
    id: 9,
    _tag: "CSidebarNavDropdown",
    name: getTranlationPath("imanage"),
    route: "/imanage",
    icon: iManageIcon,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("imanage_matter"),
        to: "/imanage/associated-matters",
      },
    ],
  },
  {
    id: 10,
    _tag: "CSidebarNavItem",
    name: getTranlationPath("clause_bank"),
    to: "/clause-bank",
    icon: documentText,
  },
  {
    id: 11,
    _tag: "CSidebarNavItem",
    name: getTranlationPath("timesheet"),
    to: "/tracker",
    icon: clock,
  },

  {
    id: 12,
    _tag: "CSidebarNavDropdown",
    name: getTranlationPath("contact"),
    route: "/contact",
    icon: contact,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("manage_contacts"),
        to: "/contact",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("contact_tags"),
        to: "/contact/tags",
      },
    ],
  },
  {
    id: 13,
    _tag: "CSidebarNavDropdown",
    name: getTranlationPath("help_and_notification"),
    route: "/notification",
    icon: help,
    _children: [
      {
        icon: openNewWindow,
        _tag: "CSidebarNavItem",
        name: getTranlationPath("support"),
        to: "//lawtoolbox.com/support",
        target: "_blank",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("app_notification"),
        to: `${sidebarRoute.APP_NOTIFICAITON_ROUTE}`,
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("whats_new"),
        to: `${sidebarRoute.WHATS_NEW_ROUTE}`,
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("onboarding"),
        to: `${sidebarRoute.ONBOARDING_ROUTE}`,
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("powershell"),
        to: `${sidebarRoute.POWERSHELL_ROUTE}`,
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("training"),
        to: `${sidebarRoute.TRAINING_ROUTE}`,
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("ltb_support"),
        to: `${sidebarRoute.SUPPORT_ROUTE}`,
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("rule_update"),
        to: `${sidebarRoute.RULE_UPDATE_ROUTE}`,
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("admin_release_notes"),
        to: `${sidebarRoute.ADMIN_RELEASE_NOTES_ROUTE}`,
      },
    ],
  },
  {
    id: 14,
    _tag: "CSidebarNavDropdown",
    name: getTranlationPath("manage_graph_event"),
    route: "/event",
    icon: template,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("find_events"),
        to: "/event/find-events",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("duplicate_events"),
        to: "/event/duplicate-events",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("cancelled_events"),
        to: "/event/cancelled-events",
      },

    ],
  },
  {
    id: 15,
    _tag: "CSidebarNavDropdown",
    name: getTranlationPath("ai"),
    icon: ai,
    route: "/ai",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("prompt"),
        to: "/ai/index",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("open_ai_setting"),
        to: "/ai/openAISetting",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("document_manager"),
        to: "/ai/documentManager",
      },
      {
        _tag: "CSidebarNavItem",
        name: getTranlationPath("ai_tag"),
        to: "/ai/tags",
      },
    ]
  },

  // {
  //   id:14,
  //   _tag: "CSidebarNavItem",
  //   name: getTranlationPath("help"),
  //   to: "/help",
  //   icon: help,
  // },
];

export default _nav;
